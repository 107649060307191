import PropTypes from 'prop-types'
import React from 'react'

import { useMediaCardData } from '~hooks'
import { createMarkup } from '~utilities'

import Featured from '~components/topic/featured'
import Recommended from '~components/detail/recommended'
import ShareButton from '~components/detail/share-button'
import { MediaCard, Tags } from '~components/shared'

const TopicBody = ({ topic }) => {
  const articles = useMediaCardData()

  let firstSectionMediaCards = []
  if (topic.firstSectionArticles) {
    topic.firstSectionArticles.forEach(article => {
      firstSectionMediaCards.push(articles.find(({ contentful_id }) => contentful_id === article.contentful_id))
    })
  }

  let secondSectionMediaCards = []
  if (topic.secondSectionArticles) {
    topic.secondSectionArticles.forEach(article => {
      secondSectionMediaCards.push(articles.find(({ contentful_id }) => contentful_id === article.contentful_id))
    })
  }

  // construct array of primary and secondary tags
  let tags = topic.primaryTopics
  if (topic.secondaryTopics && topic.secondaryTopics.length > 0) {
    tags = tags.concat(topic.secondaryTopics)
  }

  return (
    <div className='w-full lg:w-3/4 pr-4'>
      <div className='headline-1'>{topic.title}</div>
      <div className='py-4'>
        <hr className='accent-rule' aria-hidden />
        <div className='flex flex-col lg:flex-row justify-between lg:items-center py-4'>
          <Tags tags={tags} />
          <div className='mt-4 lg:mt-0'>
            <ShareButton label='share-page' />
          </div>
        </div>
        <hr className='accent-rule' aria-hidden />
      </div>

      <div className='intro my-4 lg:my-8'>{topic.subline.internal.content}</div>

      {topic?.featuredArticle?.contentful_id && <Featured id={topic.featuredArticle.contentful_id} />}

      {topic?.firstSectionHeadline && <div className='headline-3'>{topic.firstSectionHeadline}</div>}

      {topic?.firstSectionIntro?.childMarkdownRemark && (
        <div
          className='body-4 mt-4 mb-4'
          dangerouslySetInnerHTML={topic.firstSectionIntro.childMarkdownRemark.html |> createMarkup}
        />
      )}

      {firstSectionMediaCards.length > 0 &&
        firstSectionMediaCards.map(({ id, image, title, caption, url, type }) => (
          <MediaCard
            key={id}
            image={image}
            title={title}
            caption={caption}
            url={url}
            type={type}
            displayStyle='list'
            className='mt-8'
          />
        ))}

      {topic?.secondSectionHeadline && (
        <div className='headline-section my-16'>
          <div>{topic.secondSectionHeadline}</div>
          <hr />
        </div>
      )}

      {secondSectionMediaCards.length > 0 && (
        <>
          <div className='flex flex-col lg:flex-row lg:justify-between lg:-mx-4 lg:mb-8'>
            {secondSectionMediaCards?.slice(0, 2)?.map(({ id, image, title, caption, url, type }) => (
              <div key={id} className='flex lg:w-1/2 lg:px-4'>
                <MediaCard image={image} title={title} caption={caption} url={url} type={type} className='mt-8' />
              </div>
            ))}
          </div>
          {secondSectionMediaCards.slice(2)?.map(({ id, image, title, caption, url, type }) => (
            <MediaCard
              key={id}
              image={image}
              title={title}
              caption={caption}
              url={url}
              type={type}
              displayStyle='list'
              className='mt-8'
            />
          ))}
        </>
      )}

      <Recommended topicPage />
    </div>
  )
}

TopicBody.propTypes = {
  topic: PropTypes.object
}

export default TopicBody
