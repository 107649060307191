import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import { useMediaCardData } from '~hooks'

const Featured = ({ id }) => {
  const { image, title, caption, url, type } = useMediaCardData(id)
  const data = useStaticQuery(staticQuery)
  const { settings } = data
  const placeholderImage = settings.mediaCardPlaceholderImage.file.url

  return (
    <>
      <div className='aspect-ratio-16/9'>
        <img
          src={`${image || placeholderImage}?fm=jpg&fl=progressive&w=960&q=80`}
          className='w-full h-full object-cover'
          alt='N/A'
          aria-hidden
        />
      </div>
      <div className='md:relative flex flex-col lg:w-1/2 lg:-mt-32 mb-16 pt-4 lg:pr-4 bg-white'>
        <div className='headline-3 mb-2 text-blue'>{title}</div>
        <p className='body-4 flex-grow mb-4 text-clamp-3'>{caption}</p>
        <div>
          <div className='button button-pink mt-4 mb-0'>
            <Link to={url}>
              {type === 'Video' ? <FormattedMessage id='continue-watching' /> : <FormattedMessage id='read-more' />}{' '}
              <em className='fas fa-caret-right ml-2' />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Featured

const staticQuery = graphql`
  {
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      mediaCardPlaceholderImage {
        file {
          url
        }
      }
    }
  }
`
