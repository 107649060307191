import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Breadcrumbs from '~components/detail/breadcrumbs'
import TopicBody from '~components/topic/body'
import Sidebar from '~components/sidebar'

const TopicPage = props => {
  const { section, locale } = props.pageContext
  const { location } = props

  const { topic, settings, sidebarContent } = props.data
  const { showFeaturedVideo, showFeaturedActivities } = topic

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO
        title={topic?.metaTitle || topic.title}
        description={topic?.metaDescription || topic.subline.internal.content}
        articleId={topic?.featuredArticle?.contentful_id || null}
      />
      <div className='max-w-page pt-4 lg:py-8'>
        <Breadcrumbs title={topic.title} type='Topic' primaryTopics={topic.primaryTopics} />
      </div>
      <hr className='accent-rule hidden lg:block' aria-hidden />
      <div className='flex flex-col lg:flex-row max-w-page py-4 lg:py-8'>
        <TopicBody topic={topic} settings={settings} />
        <Sidebar
          sidebarContent={sidebarContent}
          showFeaturedVideo={showFeaturedVideo ?? true}
          showFeaturedActivities={showFeaturedActivities ?? true}
        />
      </div>
    </Layout>
  )
}

export default TopicPage

export const topicQuery = graphql`
  query topicQuery($id: String!) {
    topic: contentfulTopicPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      primaryTopics {
        slug
        name
        id
      }
      subline {
        internal {
          content
        }
      }
      featuredArticle {
        contentful_id
      }
      firstSectionHeadline
      firstSectionIntro {
        childMarkdownRemark {
          html
        }
      }
      firstSectionArticles {
        contentful_id
      }
      secondSectionHeadline
      secondSectionArticles {
        contentful_id
      }
      showFeaturedVideo
      showFeaturedActivities
    }
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      specialTopics {
        __typename
        ... on ContentfulTopicPage {
          title
          internal {
            type
          }
          node_locale
          slug
          type
        }
      }
    }
    sidebarContent: contentfulTopicPage(id: { eq: $id }) {
      pageFeaturedDownloadLabel: featuredDownloadLabel
      pageFeaturedDownloadTitle: featuredDownloadTitle
      pageFeaturedDownloadImage: featuredDownloadImage {
        file {
          url
        }
      }
      pageFeaturedDownloadAsset: featuredDownloadAsset {
        file {
          fileName
          url
        }
      }
      pageFeaturedDownloadLink: featuredDownloadLink
      pageFeaturedDownloadCta: featuredDownloadCta
      pageFeaturedVideo: featuredVideo {
        title
        contentful_id
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedService: featuredService {
        name
        headline
        slug
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedActivities: featuredActivities {
        title
        slug
        internal {
          type
        }
        node_locale
        slug
        type
      }
      pageFeaturedQuote: featuredQuote {
        quote {
          quote
        }
        person
        personSubline
      }
    }
  }
`
